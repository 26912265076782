import * as React from "react"
import Layout from "../components/layout"
import SparkleIcon from "../img/sparkle.svg"
import SparkleMediumIcon from "../img/sparkle-medium.svg"
import { addUtmTagsInUrls, setCookieFromUrl } from "../widgets/cookie"
import { graphql } from "gatsby"

export default function About(props: any) {
  const { allContentfulCaseStudies } = props.data
  const data = allContentfulCaseStudies.nodes.map((e) => ({
    summary: e.summary,
  }))[0]
  const p = {
    ...props,
    title: "About Us:- OPA",
    description:
      "We Are a leading influencer Marketing Agency in Mumbai.   200+ brands have worked with OPA to collaborate with 80k+ registered influencers. We are building software to bring Simplicity, Speed and Scale to influencer marketing.",
  }
  React.useEffect(() => {
    setCookieFromUrl()
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  return (
    <Layout {...p}>
      <div className="mainContainer">
        <div className="container">
          <section className="case-studies-hero height-box">
            <div className="case-studies-hero-top">
              <div className="case-studies-hero-logo">
                <img
                  src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/OPA_LOGO_with_Orange_kkawi7.png"
                  alt=""
                  style={{ width: 250 }}
                />
              </div>

              <h1 style={{ visibility: "hidden" }}>OPA</h1>
              <p>
                OPA is a managed marketplace between Brands and
                Influencers.&nbsp;
                {data.summary[1].value}
                {data.summary[1].sub} brands use OPA to collaborate with&nbsp;
                {data.summary[0].value}
                {data.summary[0].sub}&nbsp;influencers. We are building software
                to bring Simplicity, Speed and Scale to influencer marketing.
              </p>
              <br />
              <br />
              <p>
                Top brands like Nykaa, Sugar, HUL, Vero Moda, WOW, Plum,
                Decathlon, use OPA. We run very large campaigns (imagine 10k
                influencers posting at once on Instagram) effortlessly with the
                help of our technology.
              </p>
              <br />
              <br />
              <p>
                We are a young team of talented individuals from diverse
                backgrounds. We work very hard to foster an open, collaborative
                and empathetic environment. Since the start, we have stayed true
                to our mission and innovated with first-principles thinking. We
                are a bootstrapped, profitable and fast-growing start-up.
              </p>

              <br />
              <br />
            </div>
            <div className="case-studies-hero-bottom">
              <div className="case-studies-hero-bottom-col">
                <div
                  className="col-img rellax rellaxAnim"
                  data-rellax-speed="1"
                  data-rellax-percentage="0.5"
                >
                  <div className="col-img-single">
                    <a
                      target="_blank"
                      className="video-link"
                      href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/276081127_154667030272741_6791476404339314203_n_pwagwz.mp4"
                      rel="noreferrer"
                    >
                      <div>
                        <img
                          className="col-img-big"
                          src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/Set_wet_gif_nh1i24.gif"
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-studies-hero-bottom-col">
                <div
                  className="col-img rellax rellaxAnim"
                  data-rellax-speed="-1"
                  data-rellax-percentage="0.5"
                >
                  <a
                    className="video-link"
                    target="_blank"
                    href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/140171229_1001573007037225_522301390847412869_n_guyxwo.mp4"
                    rel="noreferrer"
                  >
                    <div className="col-img-single">
                      <img
                        className="col-img-big"
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/GIF_5_m4fcs7.gif"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="case-studies-hero-bottom-col">
                <div
                  className="col-img rellax rellaxAnim"
                  data-rellax-speed="1"
                  data-rellax-percentage="0.5"
                >
                  <div className="col-img-single">
                    <a
                      className="video-link"
                      target="_blank"
                      href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/Reel_5SD_kkeonh.mp4"
                      rel="noreferrer"
                    >
                      <img
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/GIF_5SD_xboher.gif"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-img-single">
                    <a
                      className="video-link"
                      target="_blank"
                      href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/Reel_4_ucctax.mp4"
                      rel="noreferrer"
                    >
                      <img
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/4_rsibni.gif"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-studies-hero-bottom-col">
                <div
                  className="col-img rellax rellaxAnim"
                  data-rellax-speed="-1"
                  data-rellax-percentage="0.5"
                >
                  <div className="col-img-single">
                    <a
                      className="video-link"
                      target="_blank"
                      href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/GIF_4B_eei7mp.gif"
                      rel="noreferrer"
                    >
                      <img
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/GIF_dzn7rh.gif"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-img-single">
                    <a
                      className="video-link"
                      target="_blank"
                      href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/Reel_6L_ov5qum.mp4"
                      rel="noreferrer"
                    >
                      <img
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/Livon_gif_q2tuhr.gif"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="case-studies-hero-bottom-col">
                <div
                  className="col-img rellax rellaxAnim"
                  data-rellax-speed="1"
                  data-rellax-percentage="0.5"
                >
                  <a
                    className="video-link"
                    href="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/Reel_1_BH_ndwdpy.mp4"
                  >
                    <div className="col-img-single">
                      <img
                        className="col-img-big"
                        src="https://opa-media-optimized-delivery.s3.ap-south-1.amazonaws.com/gif_1_2_egx4vg.gif"
                        alt=""
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="cs-sparkle-1-1 rellax" data-rellax-speed="1">
              <SparkleIcon />
            </div>
            <div className="cs-sparkle-1-2 rellax" data-rellax-speed="-1">
              <SparkleMediumIcon />
            </div>
            <div className="cs-sparkle-1-3 rellax" data-rellax-speed="2">
              <SparkleMediumIcon />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulCaseStudies {
      nodes {
        summary {
          id
          key
          value
          sub
        }
      }
    }
  }
`
